import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'

const SEO = ({
    description,
    lang,
    image: metaImage,
    title,
    titleTemplate,
    pathname,
    canonical,
    nextPage,
    prevPage,
    rootPath,
    isBlogPost,
}) => {
    const intl = useIntl()

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        description
                        author
                        siteUrl
                        canonical
                        siteLanguage
                        image
                        titleTemplate
                        twitterUsername
                    }
                    buildTime
                }
            }
        `
    )

    const metaTitle = title
    const template = titleTemplate || site.siteMetadata.titleTemplate
    const metaDescription =
        description ||
        intl.formatMessage({
            id: 'siteMetadata.description',
        })
    const language = intl.locale
    const siteUrl = site.siteMetadata.siteUrl.replace(/\/$/, '')
    const bannerImage =
        metaImage && metaImage.src
            ? `${siteUrl}/${metaImage.src}`
            : `${siteUrl}/${site.siteMetadata.image}`
    const canonicalLink = canonical || site.siteMetadata.canonical
    const imgWidth = metaImage?.width ? metaImage.width : 875
    const imgHeight = metaImage?.height ? metaImage.height : 554
    const rootUrl = siteUrl + rootPath
    const prevLink = prevPage && prevPage !== null && rootUrl + prevPage
    const nextLink = nextPage && nextPage !== null && rootUrl + nextPage
    let siteTitle = `${template}`
    if (metaTitle) {
        siteTitle += ` | ${metaTitle}`
    }

    const basSchema = [
        {
            '@type': 'Organization',
            '@id': `${siteUrl}/#organization`,
            name: `${siteTitle}`,
            url: siteUrl,
            logo: {
                '@type': 'ImageObject',
                url: site.siteMetadata.logo,
            },
        },
        {
            '@type': 'WebSite',
            '@id': `${siteUrl}/#website`,
            url: siteUrl,
            name: `${siteTitle}`,
            publisher: {
                '@id': `${siteUrl}/#organization`,
            },
            inLanguage: language,
            potentialAction: {
                '@type': 'SearchAction',
                target: `${siteUrl}/?s={search_term_string}`,
                'query-input': 'required name=search_term_string',
            },
        },
    ]

    const schemaOrgWebPage = {
        '@context': 'http://schema.org',
        '@graph': [...basSchema],
    }

    return (
        <Helmet
            htmlAttributes={{
                lang: language,
            }}
        >
            {/* General tags */}
            <title>{siteTitle}</title>
            <meta name="description" content={metaDescription} />
            {bannerImage && <meta name="image" content={bannerImage} />}
            <link rel="canonical" href={canonicalLink} />
            <meta
                name="robots"
                content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
            {prevLink && <link rel="prev" href={prevLink} />}
            {nextLink && <link rel="next" href={nextLink} />}

            {/* OpenGraph tags */}
            <meta property="og:locale" content={language} />
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalLink} />
            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={metaDescription} />
            {bannerImage && (
                    <meta property="og:image" content={bannerImage} />
                ) && (
                    <meta
                        property="og:image:secure_url"
                        content={bannerImage}
                    />
                )}
            <meta property="og:image:width" content={`${imgWidth}px`} />
            <meta property="og:image:height" content={`${imgHeight}px`} />
            <meta property="og:image:alt" content={siteTitle} />
            <meta property="og:image:type" content="image/png" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            {/* < meta
                name="twitter:creator"
                content={site.siteMetadata.twitterUsername}
            /> */}
            <meta name="twitter:title" content={siteTitle} />
            <meta name="twitter:description" content={metaDescription} />
            {bannerImage && <meta name="twitter:image" content={bannerImage} />}

            <script type="application/ld+json">
                {JSON.stringify(schemaOrgWebPage)}
            </script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    description: ``,
}

export default SEO
